import type { BonusProgramField } from "./profile";
import type { CloudinaryImage } from "@/types/image";
import type { Links } from "@/types/layout";
import type {
  BasicField,
  SelectBox,
  GenericField,
  Radio,
  TextField,
  ActionButton,
  DateOfBirthField,
  Guideline,
} from "@/types/form";
import type { Address, Contact, Person } from "@/types/request";

export interface Price {
  value: number;
  currency: string;
  formatted: string;
  prefix?: string;
  context: string;
}

export enum OrderStepEnum {
  CRUISE_INFO = "CRUISE_INFO",
  OCCUPANCY = "OCCUPANCY",
  CATEGORY = "CATEGORY",
  CABIN = "CABIN",
  ADDITIONS = "ADDITIONS",
  PERSONAL_DATA = "PERSONAL_DATA",
  CONFIRMATION = "CONFIRMATION",
  TRANSFERS = "TRANSFERS",
  TRAVELERS = "TRAVELERS",
  EXTRAS = "EXTRAS",
  EXTRA_PROGRAMS = "EXTRA_PROGRAMS",
  PAYMENT = "PAYMENT",
  AUDIT = "AUDIT",
}

export enum PaymentTypeEnum {
  INVOICE = "INVOICE",
}

export enum ProgramTypeEnum {
  PRE_TRANSIT = "PRE_TRANSIT",
  PRE_IN_PLACE = "PRE_IN_PLACE",
  PRE_STOP_OVER = "PRE_STOP_OVER",
  POST_TRANSIT = "POST_TRANSIT",
  POST_IN_PLACE = "POST_IN_PLACE",
  POST_STOP_OVER = "POST_STOP_OVER",
}

export interface CreateOrderOccupancy {
  adults: string;
  children: Array<string>;
  cabin_type?: string;
  loyalty_program_member?: boolean;
  loyalty_program_name?: string;
  loyalty_program_member_number?: string;
}

export interface CreateOrder {
  cruise_id: string;
  occupancy: CreateOrderOccupancy;
}

export interface OrderStep {
  _links: Links;
  context: string;
  checked: boolean;
  order_step: OrderStepEnum;
  label: string;
}

export interface CruiseInfoStep extends OrderStep {
  ship: string;
  duration: string;
  departures: string;
}

export interface OccupancyStep extends OrderStep {
  adults_count: string;
  children_count: string;
  loyalty_program_count: string;
  price_per_person: Price;
}

export interface CategoryStep extends OrderStep {
  cabin_type_name: string;
  tariff_title: string;
  category_name: string;
}

export interface CabinStep extends OrderStep {
  deck_name: string;
  deck_number: string;
  cabin: string;
}

export interface TariffsStep extends OrderStep {
  hotel_price?: Price;
  flight_price?: Price;
  extras_price?: Price;
}
export interface Order {
  _links: Links;
  current_step: OrderStepEnum;
  order_steps: Array<OrderStep>;
  action_label: string;
  title: string;
  cruise_id: string;
  loading_message: {
    title: string;
    text: string;
  };
}

export interface CabinLaf {
  cabin_type: string;
  amount: {
    context: string;
    currency: string;
    formatted: string;
    prefix: string;
    value: number;
  };
}

export interface CabinType {
  label: string;
  selected: boolean;
  laf: CabinLaf;
}

export interface CabinTariff {
  css_icon: string;
  title: string;
  subtitle: string;
  details: string;
  price_promo_code: string;
  onboard_credit: Price;
  price_per_person: Price;
  selection: Radio;
}

export interface InputRate {
  price_per_person: Price;
  onboard_credit?: Price;
}

export interface Rate {
  cabin_type: string;
  image: CloudinaryImage;
  title: string;
  subtitle: string;
  details: Array<string>;
  show_more_button: {
    label: string;
  };
  show_less_button: {
    label: string;
  };
  tariffs: Array<CabinTariff>;
}

export interface CabinRate extends Rate {
  category_name: string;
}

export interface BookingCabin {
  active: boolean;
  cabin_type_name: string;
  cabin_type: string;
  lowest_price_available: Price;
  rates: Array<CabinRate>;
}

export interface CabinUpgradeRate extends Rate, InputRate {
  promo_code: string;
  category_name: string;
  upgrade_button: ActionButton;
}

export interface BookingCabinUpgrade {
  cabin_type: string;
  title: string;
  upgrade_rates: Array<CabinUpgradeRate>;
  lowest_price_available: string;
  show_more_button: {
    label: string;
  };
  show_button: {
    label: string;
  };
  selected_label: string;
}

export interface Cabin {
  code: string;
  deck_code: string;
  selection: Radio;
}

export interface BookingDeck {
  active: boolean;
  name: string;
  code: string;
  cabins: Array<Cabin>;
  deck_plan?: CloudinaryImage;
  deck_plan_title: string;
}

export interface BookingUpgradeBlock {
  title: string;
  guideline: string;
  upgrades: Array<BookingCabinUpgrade>;
}

export interface BookingPersonalData {
  guideline: {
    title: string;
    text: string;
  };
  salutation_select: SelectBox;
  title_select: SelectBox;
  nationality_select: SelectBox;
  last_name_field: TextField;
  first_name_field: TextField;
  middle_names_field: TextField;
  date_of_birth: DateOfBirthField;
}

export interface PaymentData {
  guideline: {
    title: string;
    text: string;
  };
  payment_type_select: SelectBox;
}

export interface CouponData {
  guideline: {
    title: string;
    text: string;
  };
  coupon_number_field: GenericField;
  coupon_text_field: GenericField;
  coupon_value_field: GenericField;
}

export interface Phone extends BasicField {
  communication_type: string;
}

export interface ContactData {
  email: BasicField;
  mobile: Phone;
  phone: Phone;
}

export interface BookingContactData {
  guideline: {
    title: string;
    text: string;
  };
  email: BasicField;
  mobile: Phone;
  phone: Phone;
  sign_up_newsletter: Checkbox;
  sign_up_mygo7seas: Checkbox;
}

export interface BonusProgrammDataFields {
  guideline: {
    title: string;
    text: string;
  };
  cruise_line_bonus_programs: BonusProgramField;
  airline_bonus_program: BonusProgramField;
}

export interface Traveller {
  bonus_program_data_fields: BonusProgrammDataFields;
  personal_data_fields: {
    guideline: {
      title: string;
      text: string;
    };
    salutation_select: SelectBox;
    title_select: SelectBox;
    nationality_select: SelectBox;
    last_name_field: GenericField;
    first_name_field: GenericField;
    middle_name_field: GenericField;
    date_of_birth: {
      label: string;
      required: boolean;
      day_field: GenericField;
      month_field: GenericField;
      year_field: GenericField;
    };
  };
}

export interface ContractingPartySelectionOption {
  address: Address;
  airline_bonus_program: string;
  contact_data: Array<Contact>;
  cruise_line_bonus_program: string;
  customer_id: number;
  personal_data: Person;
}

export interface ContractingPartySelection {
  title: string;
  selection: Array<Radio>;
  options: Array<ContractingPartySelectionOption>;
}

export interface ContractingParty {
  title: string;
  contracting_party_selection: ContractingPartySelection;
  personal_data_fields: BookingPersonalData;
  contact_data_fields: BookingContactData;
  payment_data_fields: PaymentData;
  coupon_data_fields: CouponData;
}

export interface Dining {
  title: string;
  guideline: {
    title: string;
    text: string;
  };
  table_time_field: SelectBox;
  table_size_field: SelectBox;
  special_wishes_field: TextField;
}

export interface InsuranceOption {
  title: string;
  price_per_person: Price;
  description?: string;
  selection: Radio;
  logo?: CloudinaryImage;
}

export interface Insurance {
  title: string;
  insurance_options: Array<InsuranceOption>;
}

export interface BookingExtra {
  title: string;
  details: string;
  selection: Radio;
  price_per_person: Price;
  count_selection: SelectBox;
}

export interface ProgramRate {
  title: string;
  subtitle: string;
  image: CloudinaryImage;
  operator_logo: CloudinaryImage;
  price_per_person: Price;
  show_more_Button: {
    label: string;
  };
  show_less_button: {
    label: string;
  };
  selection: Radio;
  details: Array<string>;
}

export interface BookingProgram {
  duration_select: SelectBox;
  rates: Array<ProgramRate>;
  program_type: ProgramTypeEnum;
  mandatory: boolean;
  extra_program_id: string;
}

export interface ExtraPrograms {
  extra_program: BookingProgram;
}

export interface BookingTransferBusResource {
  departure_date_select: SelectBox;
  departure_station_select: SelectBox;
  guideline: string;
  links: {
    empty: boolean;
  };
  remark: string;
  return_date_select: SelectBox;
  title: string;
}

export interface BookingTransferFlightResource {
  departure_airport_select: SelectBox;
  departure_date_select: SelectBox;
  guideline: string;
  links: {
    empty: boolean;
  };
  remark: string;
  return_date_select: SelectBox;
  title: string;
}

export interface BookingBusFareTour {
  bus_logo: CloudinaryImage;
  departure_station: string;
  description: string;
  destination_station: string;
}

export interface BookingBusFare {
  details: string;
  price_per_person: Price;
  selection: Radio;
  show_more_button: {
    label: string;
  };
  show_less_button: {
    label: string;
  };
  tours: Array<BookingBusFareTour>;
}

export interface BookingBusFares {
  bus_tours: Array<BookingBusFare>;
}

export interface Preferences {
  guideline: Guideline;
  adults_select: SelectBox;
  first_child_age_select: SelectBox;
  second_child_age_select: SelectBox;
  cabin_types_select: Array<SelectBox>;
}

export interface Transfer {
  guideline: Guideline;
  airport_select: SelectBox;
}

export interface PersonExtraPrograms {
  guideline: Guideline;
  message_field: BasicField;
}

export interface PersonContact {
  salutation_select: SelectBox;
  title_select: SelectBox;
  last_name_field: BasicField;
  first_name_field: BasicField;
  email_field: BasicField;
  phone_field: Phone;
  note_field: BasicField;
}

export interface PersonalOffer {
  title: string;
  guideline: string;
  form: {
    title: string;
    preferences: Preferences;
    transfer: Transfer;
    extra_programs: PersonExtraPrograms;
    contact: PersonContact;
  };
  privacy_notices: string;
  action_button: ActionButton;
}
